import Vue from "vue";
export default Vue.extend({
    data() {
        return {
            data: {
                officeId: undefined,
                price: undefined
            },
            offices: []
        };
    },
    async created() {
        this.$options.officeId =
            this.data.officeId?.toString() ?? "0";
        this.data.price = 0;
        this.offices = await this.$store.dispatch("office/getAll", {
            load: false
        });
    }
});
